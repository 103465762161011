import React from "react"
import useBatch from "../hooks/useBatch"
import { CreditCard, DownloadCloud } from "react-feather"

function BatchForm() {
  const { state, action } = useBatch()

  const renderServices = (server) => {
    if (server === 1) {
      // return text with image
      return (
        <>
          {state.services
            ?.filter((item) => item.api_provider === "vip")
            .map((item, index) => {
              return (
                <div className="d-flex m-2" key={index}>
                  <img
                    style={{ width: "18px" }}
                    className="me-2"
                    src={
                      window.location.origin +
                      `/assets/images/icons/${item.group}.svg`
                    }
                    alt="logo"
                  />
                  <span className="link-title">{item.name}</span>
                </div>
              )
            })}
        </>
      )
    } else if (server === 2) {
      // return text with image
      return (
        <>
          {state.services
            ?.filter(
              (item) =>
                (item.api_provider === "getstock" &&
                  item.slug === "istockphoto") ||
                item.slug === "gettyimages"
            )
            .map((item, index) => {
              return (
                <div className="d-flex m-2" key={index}>
                  <img
                    style={{ width: "18px" }}
                    className="me-2"
                    src={
                      window.location.origin +
                      `/assets/images/icons/${item.group}.svg`
                    }
                    alt="logo"
                  />
                  <span className="link-title">{item.name}</span>
                </div>
              )
            })}
        </>
      )
    } else if (server === 3) {
      // return text with image
      return (
        <>
          {state.services
            ?.filter(
              (item) =>
                item.api_provider === "getstock" &&
                item.slug !== "istockphoto" &&
                item.slug !== "gettyimages"
            )
            .map((item, index) => {
              return (
                <div className="d-flex m-2" key={index}>
                  <img
                    style={{ width: "18px" }}
                    className="me-2"
                    src={
                      window.location.origin +
                      `/assets/images/icons/${item.group}.svg`
                    }
                    alt="logo"
                  />
                  <span className="link-title">{item.name}</span>
                </div>
              )
            })}
        </>
      )
    }
  }

  return (
    <>
      <div className="mb-3">
        <label className="form-label">Pilih Server</label>
        <div className="d-flex mb-3">
          <button
            className={`btn btn-xs me-2 ${
              state.server === 1 ? "btn-primary" : "btn-outline-primary"
            }`}
            onClick={() => action.handleChangeServer(1)}
          >
            Server 1
          </button>
          <button
            className={`btn btn-xs me-2 ${
              state.server === 2 ? "btn-primary" : "btn-outline-primary"
            }`}
            onClick={() => action.handleChangeServer(2)}
          >
            Server 2
          </button>
          <button
            className={`btn btn-xs me-2 ${
              state.server === 3 ? "btn-primary" : "btn-outline-primary"
            }`}
            onClick={() => action.handleChangeServer(3)}
          >
            Server 3
          </button>
        </div>
        <div className="border rounded p-1 d-flex flex-wrap justify-content-center">
          {renderServices(state.server)}
        </div>
      </div>
      <form className="forms-sample" onSubmit={(e) => action.handleBatch(e)}>
        <div className="mb-3">
          <label className="form-label">URL Gambar</label>
          <textarea
            ref={state.batchRef}
            className="form-control"
            autoComplete="off"
            rows={15}
            style={{ resize: "none" }}
          ></textarea>
          <small className="text-muted mt-2">
            Note :
            <ul>
                <li>Pisahkan setiap URL dengan baris baru, maksimal 10 URL</li>
                <li>Pastikan layanan terdapat di server yang anda pilih </li>
            </ul>
          </small>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <button
            type="submit"
            className="btn btn-primary btn-icon-text"
            disabled={state.isRequest}
          >
            <DownloadCloud className="btn-icon-prepend" />
            <span className="px-1">Download</span>
          </button>
          <div>
            <a
              href="/topup"
              className="btn btn-sm btn-outline-primary btn-icon-text"
            >
              <CreditCard className="btn-icon-prepend" />
              Topup Credit
            </a>
          </div>
        </div>
        {state.isRequest || state.batchResult.total ? (
          <div>
            <div class="alert alert-primary" role="alert">
              <div>
                Sedang mendownload {state.batchResult.process}/
                {state.batchResult.total} gambar
              </div>
              <div class="progress mt-3">
                <div
                  class="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  style={{
                    width: `${
                      (state.batchResult.process / state.batchResult.total) *
                      100
                    }%`,
                  }}
                  aria-valuenow={state.batchResult.process}
                  aria-valuemin="0"
                  aria-valuemax={state.batchResult.total}
                ></div>
              </div>
              <div className="d-flex justify-content-end mt-3">
                <span className="me-4">
                  Berhasil : {state.batchResult.success}
                </span>
                <span>Gagal : {state.batchResult.error}</span>
              </div>
              {state.batchResult.total === state.batchResult.process ? (
                state.batchResult.error || state.batchResult.success ? (
                  <>
                    <hr />
                    Status Download :
                    <ul>
                      {state.batchResult.messages.map((item, index) => {
                        return <li key={index}>{item.message}</li>
                      })}
                    </ul>
                  </>
                ) : null
              ) : null}
            </div>
          </div>
        ) : null}
      </form>
    </>
  )
}

export default BatchForm
