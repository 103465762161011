import React from "react";
import ReactDOM from "react-dom";
import Chart from "react-apexcharts";
import useChart, { ChartProvider } from "../../hooks/useChart";

function PaymentChart(props) {
    const { state, action } = useChart();
    const colors = {
        primary: "#6571ff",
        secondary: "#7987a1",
        success: "#05a34a",
        info: "#66d1d1",
        warning: "#fbbc06",
        danger: "#ff3366",
        light: "#e9ecef",
        dark: "#060c17",
        muted: "#7987a1",
        gridBorder: "rgba(77, 138, 240, .15)",
        bodyColor: "#000",
        cardBg: "#fff",
    };
    const series = [
        {
            name: "Pendapatan",
            data: state.payment,
        },
    ];
    const options = {
        chart: {
            type: "bar",
            parentHeightOffset: 0,
            foreColor: colors.bodyColor,
            background: colors.cardBg,
            toolbar: {
                show: false,
            },
        },
        tooltip: {
            theme: "light",
        },
        colors: ["#BF89F5", colors.danger, colors.warning],
        fill: {
            opacity: 0.9,
        },
        grid: {
            padding: {
                bottom: -4,
            },
            borderColor: colors.gridBorder,
            xaxis: {
                lines: {
                    show: true,
                },
            },
        },
        xaxis: {
            type: "text",
            categories: state.paymentLabel,
            axisBorder: {
                color: colors.gridBorder,
            },
            axisTicks: {
                color: colors.gridBorder,
            },
        },

        labels: state.paymentLabel,
        yaxis: {
            title: {
                text: "Pendapatan",
                style: {
                    size: 5,
                    color: colors.muted,
                },
            },
            labels: {
                show: true,
                formatter: function (val) {
                    return new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                    }).format(val);
                },
            },
        },
        stroke: {
            width: 0,
        },
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            bar: {
                columnWidth: "40%",
                borderRadius: 4,
                dataLabels: {
                    position: "top",
                    orientation: "vertical",
                },
            },
        },
    };

    return (
        <div class="card-body">
            <div class="d-flex justify-content-between align-items-baseline mb-2">
                <h6 class="card-title mb-0">Pendapatan</h6>
            </div>
            <div class="row align-items-start mb-2">
                <div class="col-md-6">
                    <p class="text-muted tx-13 mb-3 mb-md-0">
                        Estimasi pendapatan dalam kurun waktu tertentu
                    </p>
                </div>
                <div class="col-md-6 d-flex justify-content-md-end">
                    <div
                        class="btn-group mb-3 mb-md-0"
                        role="group"
                        aria-label="Basic example"
                    >
                        <button
                            class={`btn btn-xs ${
                                state.paymentState === "monthly"
                                    ? "btn-primary"
                                    : "btn-outline-primary"
                            }`}
                            onClick={() => {
                                action.getChart("payment", "monthly");
                                action.setPaymentState("monthly");
                            }}
                        >
                            Bulan ini
                        </button>
                        <button
                            class={`btn btn-xs ${
                                state.paymentState === "yearly"
                                    ? "btn-primary"
                                    : "btn-outline-primary"
                            }`}
                            onClick={() => {
                                action.getChart("payment", "yearly");
                                action.setPaymentState("yearly");
                            }}
                        >
                            Tahun ini
                        </button>
                    </div>
                </div>
            </div>
            {state.isDownloadLoading ? (
                <div className="d-flex justify-content-center align-items-center h-100">
                    <i className="mdi mdi-loading mdi-spin me-2 mdi-24px"></i>
                    <h4>Loading</h4>
                </div>
            ) : (
                <Chart
                    options={options}
                    series={series}
                    type="bar"
                    height={275}
                />
            )}
        </div>
    );
}

if (document.getElementById("payment-chart")) {
    ReactDOM.render(
        <ChartProvider>
            <PaymentChart />
        </ChartProvider>,
        document.getElementById("payment-chart")
    );
}
