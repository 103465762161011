import axios from 'axios'
import React, { createContext, useContext, useState, useEffect } from 'react'
import Swal from 'sweetalert2'

const serviceContext = createContext(null)

export const ServiceProvider = ({ children }) => {
    const initResult = {
        status: '',
        message: '',
    }

    const token = document.head.querySelector('meta[name="_token"]').content
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
    })

    const Confirm = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger me-2',
        },
        buttonsStyling: false,
    })

    const [showEditModal, setShowEditModal] = useState(false)
    const [showCreateModal, setShowCreateModal] = useState(false)

    const [services, setServices] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [form, setForm] = useState({})

    const [page, setPage] = useState(1)
    const [totalItem, setTotalItem] = useState(0)

    const handlePageChange = (pages) => {
        getHistory(pages, filter)
        setPage(page)
    }

    const openEditModal = (id) => {
        getServicesById(id)
        setShowEditModal(true)
    }

    const openCreateModal = () => {
        setShowCreateModal(true)
    }

    const closeCreateModal = () => {
        setShowCreateModal(false)
    }

    const closeEditModal = () => {
        setShowEditModal(false)
    }

    const handleLabel = (labels) => {
        if (form.label?.includes(labels)) {
            const newLabel = form.label.filter((label) => label !== labels)
            setForm({ ...form, label: newLabel })
        } else {
            setForm({ ...form, label: [...form.label, labels] })
        }
    }

    const handleStatus = (status) => {
        setForm({ ...form, status: status })
    }

    const handleUpdate = (e) => {
        e.preventDefault()
        const formData = new FormData()
        Object.keys(form).forEach((key) => {
            formData.append(key, form[key])
        })
        axios
            .post(`/admin/services/update`, formData, {
                headers: {
                    'X-CSRF-TOKEN': token,
                },
            })
            .then((res) => {
                if (res.data.status === 'success') {
                    Toast.fire({
                        icon: 'success',
                        title: 'Service telah diperbarui',
                    })
                    closeEditModal()
                    getServices()
                }
            })
            .catch((err) => {
                Toast.fire({
                    icon: 'error',
                    title: err.response.data.message,
                })
            })
    }

    const handleCreate = (e) => {
        e.preventDefault()
        const formData = new FormData()
        Object.keys(form).forEach((key) => {
            formData.append(key, form[key])
        })

        axios.post(`/admin/services/create`, formData, {
            headers: {
                'X-CSRF-TOKEN': token,
            },
        }).then((res) => {
            if (res.data.status === 'success') {
                Toast.fire({
                    icon: 'success',
                    title: 'Service telah ditambahkan',
                })
                closeCreateModal()
                getServices()
            }
        })

    }

    const getServices = async (pages) => {
        setIsLoading(true)
        const response = await axios.get(`/api/v2/service`)
        if (response.status === 200) {
            setServices(response.data.data)
        }
        setIsLoading(false)
    }

    const getServicesById = async (id) => {
        const response = await axios.get(`/admin/services/${id}`)
        if (response.status === 200) {
            setForm(response.data)
        }
    }

    useEffect(() => {
        getServices()
    }, [])

    return (
        <serviceContext.Provider
            value={{
                state: {
                    isLoading,
                    page,
                    totalItem,
                    services,
                    form,
                    showEditModal,
                    showCreateModal,
                },
                action: {
                    Toast,
                    Confirm,
                    handlePageChange,
                    getServices,
                    setForm,
                    handleLabel,
                    handleStatus,
                    handleUpdate,
                    closeEditModal,
                    openEditModal,
                    openCreateModal,
                    closeCreateModal,
                    handleCreate,
                },
            }}
        >
            {children}
        </serviceContext.Provider>
    )
}

export default function useService() {
    return useContext(serviceContext)
}
