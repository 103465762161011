import React from 'react'
import ReactDOM from 'react-dom'
import useService, { ServiceProvider } from '../../hooks/useService'
import { renderServiceStatus, renderIcon, renderLabel } from '../../hooks/useHelper'
import Pagination from 'react-js-pagination'
import Select from 'react-select'
import { MoreHorizontal, Edit2 } from 'react-feather'
import EditModal from './Edit'
import CreateModal from './Create'

function Service() {
    const { state, action } = useService()

    return (
        <div className="card">
            <div className="card-body">
                <div className="m-1 mb-4 d-flex justify-content-between">
                    <h4 className="noble-ui-logo d-block">Layanan</h4>
                    <div className="d-flex">
                        <button className="btn btn-primary" onClick={() => action.openCreateModal()}>
                            <span className="ml-1">Tambah Layanan</span>
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-7 col-md-5 mb-3">
                        <div className="d-flex align-items-end justify-content-end w-100">
                            <div className="me-2 w-100">
                                <label className="text-muted">Cari</label>
                                <input className="form-control" ref={state.imageIDRef} />
                            </div>
                            <button className="btn btn-primary" onClick={() => action.handleSearch()}>
                                Cari
                            </button>
                        </div>
                    </div>
                </div>
                <div className="mb-3 table-responsive">
                    <table className="table table-hover mb-0">
                        <thead>
                            <tr>
                                <th className="pt-0">Nama</th>
                                <th className="pt-0">Slug</th>
                                <th className="pt-0 text-center">Status</th>
                                <th className="pt-0 text-center">Label</th>
                                <th className="pt-0 text-center">Api Provider</th>
                                <th className="pt-0 text-end">Harga</th>
                                <th className="pt-0 text-end">VIP</th>
                                <th className="pt-0 text-end">Bonus</th>
                                <th className="pt-0 text-end"></th>
                            </tr>
                        </thead>

                        <tbody>
                            {state.isLoading ? (
                                <tr>
                                    <td className="text-center py-5" colSpan={7}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <i className="mdi mdi-loading mdi-spin me-2 mdi-24px"></i>
                                            <h4>Loading</h4>
                                        </div>
                                    </td>
                                </tr>
                            ) : (
                                state.services?.map((item, index) => (
                                    <tr key={index}>
                                        <td className="align-middle">{item.name}</td>
                                        <td className="align-middle"><small>{item.slug}</small></td>
                                        <td className="align-middle text-center">{renderServiceStatus(item.status)}</td>
                                        <td className="align-middle text-center">{renderLabel(item.label)}</td>
                                        <td className="align-middle text-center">{item.server == "server1" ? 'vip' : 'getstock'}</td>
                                        <td className="align-middle text-end">{renderIcon('credit', 'sm', item.cost)}</td>
                                        <td className="align-middle text-end">{renderIcon('credit', 'sm', item.vip_cost)}</td>
                                        <td className="align-middle text-end">{renderIcon('bonus', 'sm', item.cost)}</td>
                                        <td class="align-end">
                                            <div class="dropdown">
                                                <button
                                                    class="btn p-0"
                                                    type="button"
                                                    id="more-menu"
                                                    data-bs-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <MoreHorizontal size={18} className="text-muted" />
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby="more-menu">
                                                    <button
                                                        class="dropdown-item d-flex align-items-center"
                                                        onClick={() => action.openEditModal(item.id)}
                                                    >
                                                        <Edit2 size={14} className="me-2" />
                                                        <span class="">Ubah Data</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>

                    <div className="mt-3">
                        <Pagination
                            activePage={state.page}
                            itemsCountPerPage={10}
                            totalItemsCount={state.totalItem}
                            pageRangeDisplayed={5}
                            onChange={action.handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

if (document.getElementById('service-page')) {
    ReactDOM.render(
        <ServiceProvider>
            <Service />
            <EditModal />
            <CreateModal />
        </ServiceProvider>,
        document.getElementById('service-page'),
    )
}

{
    /* <div class="card">
    <div class="card-body">
        <div class="m-2 mb-3">
            <h4 class="noble-ui-logo d-block">Layanan</h4>
        </div>
        @if (Session::get('message'))
            <div class="alert alert-success my-3">
                {{ Session::get('message') }}
            </div>
        @endif
        @if (Session::get('message_error'))
            <div class="alert alert-danger my-3">
                {{ Session::get('message_error') }}
            </div>
        @endif
        <div class="mb-4 table-responsive">
            <table class="table table-hover mb-0">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Nama</th>
                        <th>Slug</th>
                        <th>Status</th>
                        <th>Label</th>
                        <th class="text-end">Harga</th>
                        <th class="text-end">Harga VIP</th>
                        <th class="text-end">Harga Bonus</th>
                        <th> </th>
                    </tr>
                </thead>
                <tbody>
                    @foreach ($services as $service)
                        {{-- {{ dd($service->services->name) }} --}}
                        <tr>
                            <td class="align-middler">{{ $loop->index + 1 }}</td>
                            <td class="align-middle">{{ $service->name }}</td>
                            <td class="align-middle">{{ $service->slug }}</td>
                            <td class="align-middle">
                                @if ($service->status === 'active')
                                    <span data-bs-toggle="tooltip" data-bs-placement="top" title="Active"><i
                                            class="icon-lg text-success" data-feather="check-circle"></i></span>
                                @elseif ($service->status === 'maintenance')
                                    <span data-bs-toggle="tooltip" data-bs-placement="top"
                                        title="Maintenance"><i class="icon-lg text-danger"
                                            data-feather="x-circle"></i></span>
                                @endif
                            </td>
                            <td class="align-middle">
                                @if (!empty($service->label))
                                    @foreach (json_decode($service->label) as $label)
                                        @if ($label === 'hot')
                                            <span class="badge badge-pill bg-primary">hot</span>
                                        @elseif ($label === 'new')
                                            <span class="badge badge-pill bg-info">new</span>
                                        @elseif ($label === 'promo')
                                            <span class="badge badge-pill bg-warning">promo</span>
                                        @elseif ($label === 'maintenance')
                                            <span class="badge badge-pill bg-danger">maintenance</span>
                                        @endif
                                    @endforeach
                                @endif
                            </td>
                            <td class="align-middle text-end render-cost" data-value="{{ $service->cost }}">
                            </td>
                            <td class="align-middle text-end render-cost"
                                data-value="{{ $service->vip_cost }}"></td>
                            <td class="align-middle text-end render-bonus"
                                data-value="{{ $service->bonus_cost }}"></td>

                            <td class="align-end"><button id="edit-button"
                                    onclick="edit({{ $service->id }})" type="button" class="btn p-0"
                                    data-bs-toggle="modal" data-bs-target="#servicesModal"><i
                                        class="icon-lg text-muted pb-3px"
                                        data-feather="more-horizontal"></i></button></td>
                        </tr>
                    @endforeach
                </tbody>
            </table>
        </div>
        <div class="d-flex justify-content-end">{!! $services->links() !!}</div>
    </div>
</div> */
}
