import React from "react"
import ReactDOM from "react-dom"
import useBatch, { BatchProvider } from "../hooks/useBatch"
import BatchForm from "./BatchForm"
import InfoCredit from './InfoCredit'
import HistoryDownload from "./HistoryDownload"

function BatchDownload() {
  const { state, action } = useBatch()

  return (
      <>
        <div className="row">
          <div className="col-lg-6 col-xl-8 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-baseline mb-5">
                  <h6 className="card-title mb-0">Batch Download</h6>
                  <small class="text-muted">Terjadi masalah? <a href="/help/contact">Lapor Admin</a></small>
                </div>
                <BatchForm />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xl-4 grid-margin stretch-card">
            <InfoCredit/>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-xl-12 stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-baseline mb-2">
                  <h6 className="card-title mb-0">Riwayat Unduhan Terakhir</h6>
                  <a
                    href="/history/download"
                    className="btn btn-xs btn-outline-primary btn-icon-text"
                  >
                    <i className="btn-icon-prepend" data-feather="file-text" />
                    History
                  </a>
                </div>
              </div>
              <HistoryDownload/>
            </div>
          </div>
        </div>
      </>
  )
}

if (document.getElementById("batch-page")) {
  ReactDOM.render(
    <BatchProvider>
      <BatchDownload />
    </BatchProvider>,
    document.getElementById("batch-page")
  )
}
