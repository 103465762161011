import axios from "axios"
import React, { useEffect, useState, useRef } from "react"
import { renderIcon, formatTime, renderStatus } from "../hooks/useHelper"
import useBatch from "../hooks/useBatch"

function HistoryDownload() {
  const { state, action } = useBatch()

  const renderDownloadButton = (item) => {
    if (item.status === "success") {
      if (item.server === "server1") {
        return (
          <a href={item.url} className="btn btn-xs btn-primary">
            Download
          </a>
        )
      } else {
        return (
          <a
            href={`/services/download/${item.id}`}
            className="btn btn-xs btn-primary"
          >
            Download
          </a>
        )
      }
    } else if (item.status === "expired") {
      if (item.server === "server1") {
        return (
          <button
            disabled={state.isLoading}
            className="btn btn-xs btn-outline-danger"
            onClick={(e) => action.handleRedownload(e, item.image_id)}
          >
            Redownload
          </button>
        )
      } else {
        return null
      }
    }
  }

  return (
    <div className="table table-responsive">
      <table className="table table-hover mb-0">
        <thead>
          <tr>
            <th className="pt-0 text-center">#</th>
            <th className="pt-0 text-center">thumbnail</th>
            <th className="pt-0">Provider</th>
            <th className="pt-0">ID</th>
            <th className="pt-0">Tanggal</th>
            <th className="pt-0">Status</th>
            <th className="pt-0 text-end">Harga</th>
            <th className="pt-0 text-center">URL</th>
          </tr>
        </thead>
        <tbody>
          {state.dataHistory?.data.slice(0,10).map((item, index) => {
            return (
              <tr key={index}>
                <td className="align-middle text-center">
                  {state.dataHistory?.from + index}
                </td>
                <td className="align-middle text-center">
                  <img
                    src={item.thumbnail}
                    className="thumbnail"
                    alt="thumbnail"
                  />
                </td>
                <td className="align-middle">{item.service_name}</td>
                <td className="align-middle">{item.image_id}</td>
                <td className="align-middle">{formatTime(item.created_at)}</td>
                <td className="align-middle">{renderStatus(item.status)}</td>
                <td className="align-middle text-end">
                  {renderIcon(
                    item.credit_from === item.credit_to ? "bonus" : "credit","sm",item.cost
                  )}
                </td>
                <td className="align-middle text-center">{renderDownloadButton(item)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default HistoryDownload
