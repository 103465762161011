import axios from "axios";
import React, { createContext, useContext, useEffect } from "react";

const chartContext = createContext(null);

export const ChartProvider = ({ children }) => {
    const [downloadState, setDownloadState] = React.useState("monthly");
    const [paymentState, setPaymentState] = React.useState("monthly");
    const [isDownloadLoading, setIsDownloadLoading] = React.useState(true);
    const [download, setDownload] = React.useState([]);
    const [downloadLabel, setDownloadLabel] = React.useState([]);
    const [payment, setPayment] = React.useState([]);
    const [paymentLabel, setPaymentLabel] = React.useState([]);
    const [provider, setProvider] = React.useState([]);
    const [providerLabel, setProviderLabel] = React.useState([]);

    const getChart = (type, period) => {
        setIsDownloadLoading(true);
        axios
            .get(`/api/v2/admin/chart/${type}/${period}`)
            .then((res) => {
                if (res.data.status === "success") {
                    if (type === "download") {
                        let tmplabel = [];
                        let tmpseries = [];
                        res.data.data.map((item) => {
                            if (period === "monthly") {
                                tmplabel.push(
                                    Intl.DateTimeFormat("id", {
                                        day: "numeric",
                                    }).format(new Date(item.label.toString()))
                                );
                            }
                            if (period === "yearly") {
                                tmplabel.push(
                                    Intl.DateTimeFormat("id", {
                                        month: "short",
                                    }).format(new Date(item.label.toString()))
                                );
                            }
                            tmpseries.push(item.data);
                        });
                        setDownload(tmpseries);
                        setDownloadLabel(tmplabel);
                    } else {
                        let tmplabel = [];
                        let tmpseries = [];
                        res.data.data.map((item) => {
                            if (period === "monthly") {
                                tmplabel.push(
                                    Intl.DateTimeFormat("id", {
                                        day: "numeric",
                                    }).format(new Date(item.label.toString()))
                                );
                            }
                            if (period === "yearly") {
                                tmplabel.push(
                                    Intl.DateTimeFormat("id", {
                                        month: "short",
                                    }).format(new Date(item.label.toString()))
                                );
                            }
                            tmpseries.push(item.data);
                        });
                        setPayment(tmpseries);
                        setPaymentLabel(tmplabel);
                    }
                }
                setIsDownloadLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsDownloadLoading(false);
            });
    };

    // const getProviderChart = () => {
    //     axios.get('api/admin/chart/services').then((res) => {
    //         if(res.data.status === 'success') {
    //             let tmplabel = [];
    //             let tmpseries = [];
    //             res.data.data.map((item) => {
    //                 tmplabel.push(item.name);
    //                 tmpseries.push(item.total_download);
    //             } );
    //             setProvider(tmpseries);
    //             setProviderLabel(tmplabel);
    //         }
    //     })
    // }

    useEffect(() => {
        getChart("download", "monthly");
        getChart("payment", "monthly");
    }, []);
    return (
        <chartContext.Provider
            value={{
                state: {
                    download,
                    payment,
                    downloadLabel,
                    paymentLabel,
                    isDownloadLoading,
                    downloadState,
                    paymentState,
                    provider,
                    providerLabel,
                },
                action: {
                    getChart,
                    setDownloadState,
                    setPaymentState,
                },
            }}
        >
            {children}
        </chartContext.Provider>
    );
};

export default function useChart() {
    return useContext(chartContext);
}
