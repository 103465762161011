import React, { createContext, useContext, useRef, useState, useEffect } from 'react'
import Swal from 'sweetalert2'

const orderContext = createContext(null)

export const OrderProvider = ({ children }) => {
    const initResult = {
        status: '',
        message: '',
    }

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
    })

    const Confirm = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger me-2',
        },
        buttonsStyling: false,
    })

    const urlRef = useRef(null)
    const provider = JSON.parse(document.getElementById('order-page').getAttribute('data-provider'))

    const [result, setResult] = useState(initResult)
    const [dataHistory, setDataHistory] = useState(null)
    const [user, setUser] = useState(0)

    const [isRequest, setIsRequest] = useState(false)
    const [isDownload, setIsDownload] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleOrder = async (e) => {
        e.preventDefault()
        setIsRequest(true)
        setResult(initResult)

        const data = {
            url_id: urlRef.current.value,
            slug: provider.slug,
            api_provider: provider.server,
        }
        try {
            const response = await axios.post(`/api/v2/download/single`, data)
            if (response.status === 200) {
                setResult(response.data)
                if (response.data.status === 'success') {
                    getHistory()
                    getUser()
                }
                setIsRequest(false)
            } else {
                setIsRequest(false)
            }
        } catch (error) {
            setIsRequest(false)
            setResult({
                status: 'error',
                message: 'Terjadi kesalahan, silahkan coba lagi',
            })
            console.log(error)
        }
    }

    const handleRedownload = async (e, image_id) => {
        e.preventDefault()
        setIsLoading(true)
        Toast.fire({
            icon: 'info',
            title: 'Sedang mendownload ulang',
            timer: 0,
        })
        const data = {
            url_id: image_id,
            slug: provider.slug,
            api_provider: provider.server,
        }
        try {
            const response = await axios.post(`/api/v2/download/single`, data)
            if (response.status === 200) {
                Toast.fire({
                    icon: response.data.status,
                    title: response.data.message,
                }).then((res) => {
                    if (res.isDismissed) {
                        getHistory()
                        getUser()
                    }
                })
            } else {
                setIsLoading(false)
            }
        } catch (error) {
            setIsLoading(false)
            Toast.fire({
                icon: 'error',
                title: 'Terjadi kesalahan, silahkan coba lagi',
            })
            console.log(error)
        }
    }

    const handleDownload = async (e, task_id) => {
        e.preventDefault()
        setIsDownload(true)

        const payload = {
            task_id: task_id,
            api_provider: provider.server,
        }

        try {
            const response = await axios.post(`/api/v2/download/generate`, payload)
            if (response.data.success && response.status === 200) {
                Toast.close()
                window.location.href = response.data.downloadLink
                setIsDownload(false)
            } else {
                Toast.close()
                setIsDownload(false)
            }
        } catch (error) {
            setIsDownload(false)
            setResult({
                status: 'error',
                message: 'Terjadi kesalahan, silahkan coba lagi',
            })
            console.log(error)
        }
    }

    const getHistory = async () => {
        setIsLoading(true)
        const response = await axios.get(`/api/v2/download/history/${provider.slug}/${provider.server}`)
        if (response.status === 200) {
            setDataHistory(response.data.data)
            setIsLoading(false)
        } else {
            setIsLoading(false)
        }
    }

    const getUser = async () => {
        const response = await axios.get('/api/v2/user/current')
        if (response.status === 200) {
            setUser(response.data.data)
        }
    }

    const renderDownloadButton = (item) => {
        if (item.status === 'success') {
            if (item.server === 'server1') {
                return (
                    <button
                        onClick={(e) => {
                            handleDownload(e, item.url)
                            Toast.fire({
                                title: 'Sedang mempersiapkan file...',
                                icon: 'info',
                                timer: 0,
                            })
                        }}
                        className="btn btn-xs btn-primary"
                        disabled={isDownload}
                    >
                        Download
                    </button>
                )
            } else {
                return (
                    <a href={`/api/v2/download/generate/${item.id}`} className="btn btn-xs btn-primary">
                        Download
                    </a>
                )
            }
        } else if (item.status === 'expired') {
            if (item.full_url) {
                console.log(item)
                return (
                    <button
                        disabled={isLoading}
                        className="btn btn-xs btn-outline-danger"
                        onClick={(e) =>
                            Confirm.fire({
                                title: 'Yakin ingin mendownload ulang file ini?',
                                text: 'Anda akan dikenakan biaya sebesar ' + item.cost,
                                icon: 'info',
                                showCancelButton: true,
                                confirmButtonText: 'Redownload',
                                cancelButtonText: 'Tidak',
                                reverseButtons: true,
                            }).then((res) => {
                                console.log(res)
                                if (res.value) {
                                    handleRedownload(e, item.full_url)
                                }
                            })
                        }
                    >
                        Redownload
                    </button>
                )
            } else {
                return null
            }
        }
    }

    useEffect(() => {
        getHistory()
        getUser()
    }, [])

    return (
        <orderContext.Provider
            value={{
                state: {
                    result,
                    isRequest,
                    isLoading,
                    urlRef,
                    provider,
                    dataHistory,
                    user,
                    isDownload,
                },
                action: {
                    handleOrder,
                    handleRedownload,
                    handleDownload,
                    Toast,
                    Confirm,
                    renderDownloadButton,
                },
            }}
        >
            {children}
        </orderContext.Provider>
    )
}

export default function useOrder() {
    return useContext(orderContext)
}
