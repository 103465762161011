import React from 'react'
import { renderIcon, formatTime, renderStatus } from '../hooks/useHelper'
import useOrder from '../hooks/useOrder'

function HistoryDownload() {
    const { state, action } = useOrder()

    return (
        <div className="table table-responsive">
            <table className="table table-hover mb-0">
                <thead>
                    <tr>
                        <th className="pt-0 text-center">#</th>
                        <th className="pt-0 text-center">thumbnail</th>
                        <th className="pt-0">Provider</th>
                        <th className="pt-0">ID</th>
                        <th className="pt-0">Tanggal</th>
                        <th className="pt-0">Status</th>
                        <th className="pt-0 text-end">Harga</th>
                        <th className="pt-0 text-center">URL</th>
                    </tr>
                </thead>
                <tbody>
                    {state.dataHistory?.data.slice(0, 10).map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className="align-middle text-center">{state.dataHistory?.from + index}</td>
                                <td className="align-middle text-center">
                                    <img src={item.thumbnail} className="thumbnail" alt="thumbnail" />
                                </td>
                                <td className="align-middle">{item.service_name}</td>
                                <td className="align-middle">{item.image_id}</td>
                                <td className="align-middle">{formatTime(item.created_at)}</td>
                                <td className="align-middle">{renderStatus(item.status)}</td>
                                <td className="align-middle text-end">
                                    {renderIcon(item.credit_from === item.credit_to ? 'bonus' : 'credit', 'sm', item.cost)}
                                </td>
                                <td className="align-middle text-center">{action.renderDownloadButton(item)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default HistoryDownload
