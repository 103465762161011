import React, { useEffect } from "react";
import useBatch from "../hooks/useBatch";
import { renderFileType, renderIcon } from "../hooks/useHelper";

// let from = 0
// let page = 1
function InfoCredit() {
    const { state, action } = useBatch();

    const [services, setServices] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [from, setFrom] = React.useState(0);
    const [to, setTo] = React.useState(10);

    const handleNext = (from) => {
        if (state.services.length / 10 > page) {
            setFrom(from + 10);
            setTo(to + 10);
            setPage(page + 1);
        }
    };

    const handleBack = (from) => {
        if (from > 0) {
            setFrom(from - 10);
            setTo(to - 10);
            setPage(page - 1);
        }
    };

    useEffect(() => {
        setServices(state.services.slice(from, to));
    }, [from, to]);

    useEffect(() => {
        setServices(state.services.slice(from, to));
    }, [state.services]);

    return (
        <div className="card">
            <div className="card-body p-4">
                <div className="row my-3">
                    <div className="col text-center">
                        <h5 className="mb-2 text-muted">Credit</h5>
                        <h3 className="mb-2 align-middle">
                            {state.user.credit} {renderIcon("credit", "lg")}
                        </h3>
                    </div>
                    <div className="col text-center">
                        <h5 className="mb-2 text-muted">Bonus</h5>
                        <h3 className="mb-2">
                            {state.user.bonus} {renderIcon("bonus", "lg")}
                        </h3>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-sm border">
                        <tbody>
                            {services?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="p-2">{item.name}</td>
                                        <td className="p-2 text-end">
                                            {renderIcon(
                                                "credit",
                                                "sm",
                                                state.user.role === "vip" ? item.vip_cost : item.cost
                                            )}
                                        </td>
                                        <td className="p-2 text-end">
                                            {renderIcon(
                                                "bonus",
                                                "sm",
                                                item.bonus_cost
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <nav>
                        <ul class="pagination justify-content-center mt-2">
                            <li class="page-item">
                                <button
                                    class="page-link"
                                    onClick={() => handleBack(from)}
                                >
                                    <i data-feather="chevron-left"></i>
                                </button>
                            </li>
                            <li class="page-item disabled">
                                <a class="page-link" href="#">
                                    {page}
                                </a>
                            </li>
                            <li class="page-item">
                                <button
                                    class="page-link"
                                    onClick={() => handleNext(from)}
                                >
                                    <i data-feather="chevron-right"></i>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default InfoCredit;
