import React from "react"
import useOrder from "../hooks/useOrder"
import { renderFileType, renderIcon } from "../hooks/useHelper"

function InfoCredit() {
  const { state, action } = useOrder()

  return (
    <div className="card">
      <div className="card-body p-4">
        <div className="row my-3">
          <div className="col text-center">
            <h5 className="mb-2 text-muted">Credit</h5>
            <h3 className="mb-2 align-middle">
              {state.user.credit} {renderIcon("credit", "lg")}
            </h3>
          </div>
          <div className="col text-center">
            <h5 className="mb-2 text-muted">Bonus</h5>
            <h3 className="mb-2">
              {state.user.bonus} {renderIcon("bonus", "lg")}
            </h3>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-sm table-bordered">
            <tbody>
              <tr>
                <td>
                  <small className="fw-bold text-muted">HARGA CREDIT</small>
                </td>
                <td>
                  {state.user.role === "vip" ? state.provider.vip_cost : state.provider.cost}
                  {renderIcon("credit")}
                </td>
              </tr>
              <tr>
                <td>
                  <small className="fw-bold text-muted">HARGA BONUS</small>
                </td>
                <td>
                  {state.provider.bonus_cost}
                  {renderIcon("bonus")}
                </td>
              </tr>
              <tr>
                <td>
                  <small className="fw-bold text-muted">FILE TYPES</small>
                </td>
                <td>{state.provider.file_type}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default InfoCredit
