import { CheckCircle, XCircle, MinusCircle } from 'react-feather'
import { Tooltip } from 'react-bootstrap'

export const formatTime = (time) => {
    let date = new Date(time)
    let option = { dateStyle: 'full', timeStyle: 'short' }
    return date.toLocaleString('id-ID', option)
}

export const renderIcon = (type, size = 'sm', value = null) => {
    if (type === 'credit') {
        return (
            <>
                {value}
                <svg
                    width={size === 'sm' ? '16' : size === 'lg' ? '22' : size}
                    height={size === 'sm' ? '16' : size === 'lg' ? '22' : size}
                    viewBox="0 0 14 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9.42632 0.000781854C11.5518 0.000781854 14.4968 1.18598 16 2.64011L13.0776 5.47333C12.4708 4.92869 11.756 4.50898 10.9764 4.23966V13.7603C11.7556 13.491 12.4703 13.0719 13.0776 12.5282L16 15.3599C14.4968 16.8156 11.5518 17.9977 9.42632 17.9992C8.19433 18.0149 6.97138 17.7938 5.82847 17.3486C4.68555 16.9034 3.64543 16.243 2.76849 15.4058C1.89154 14.5685 1.19523 13.5712 0.71998 12.4715C0.244729 11.3719 0 10.1919 0 9C0 7.80812 0.244729 6.62811 0.71998 5.52847C1.19523 4.42882 1.89154 3.43145 2.76849 2.59424C3.64543 1.75702 4.68555 1.09665 5.82847 0.651444C6.97138 0.206239 8.19433 -0.0149276 9.42632 0.000781854ZM6.84016 4.67747C5.41616 5.47333 4.25401 7.40905 4.25401 9.00078C4.25401 10.5925 5.41293 12.5298 6.84016 13.3257V4.67747Z"
                        fill="#6571FF"
                    />
                </svg>
            </>
        )
    } else if (type === 'bonus') {
        return (
            <>
                {value}
                <svg
                    width={size === 'sm' ? '16' : size === 'lg' ? '22' : size}
                    height={size === 'sm' ? '16' : size === 'lg' ? '22' : size}
                    viewBox="0 0 13 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M18 7.00078H15.4487C15.5921 6.5126 15.6726 6.00814 15.6882 5.49961C15.6882 2.73026 13.4437 0.278342 10.6795 0.0265833V0H1.67478V7.00078H0V10.9961H1.67478V18H11.6922C13.1522 18 14.5523 17.4206 15.5847 16.3892C16.6171 15.3578 17.197 13.959 17.197 12.5004C17.1881 11.9881 17.0983 11.4804 16.931 10.9961H18V7.00078ZM11.6843 14H5.67861V11.0008H11.6843C12.0824 11.0008 12.4642 11.1588 12.7457 11.44C13.0272 11.7212 13.1854 12.1027 13.1854 12.5004C13.1854 12.8981 13.0272 13.2795 12.7457 13.5608C12.4642 13.842 12.0824 14 11.6843 14ZM5.67861 4H10.1833C10.5732 4.01258 10.943 4.17617 11.2143 4.45617C11.4857 4.73617 11.6374 5.11064 11.6374 5.50039C11.6374 5.89014 11.4857 6.26461 11.2143 6.54461C10.943 6.82462 10.5732 6.9882 10.1833 7.00078H5.67861V4Z"
                        fill="#BF89F5"
                    />
                </svg>
            </>
        )
    }
}

export const renderStatus = (status) => {
    if (status === 'success') {
        return <span class="badge bg-success">success</span>
    } else if (status === 'expired') {
        return <span class="badge bg-secondary">expired</span>
    } else if (status === 'failed') {
        return <span class="badge bg-danger">failed</span>
    }
}

export const renderFileType = (slug) => {
    switch (slug) {
        case 'adobestock':
            return 'Images, Audio, Templates, 3D'
        case 'shutterstock':
            return 'Images, Vector'
        case 'envato':
            return 'All Items'
        case 'depositphotos':
            return 'Images, Vector'
        case 'freepik':
            return 'Images, Vector, PSD'
        case 'istockphoto':
            return 'Images'
        case 'gettyimages':
            return 'Images'
        case 'motionarray':
            return 'All Files'
        case 'alamy':
            return 'Images'
        case 'dreamstime':
            return 'Images, Vector'
        case 'vecteezy':
            return 'Images, Vector, Video'
        case '123rf':
            return 'Images, Vector'
        case 'yellowimages':
            return 'Mockups'
        case 'vectorstock':
            return 'Images, Vector'
        case 'epidemicsound':
            return 'All Files'
        case 'creativefabrica':
            return 'All Files'

        default:
            break
    }
}

export const renderLabel = (labels) => {
    return labels?.map((label) => {
        if (label === 'new') {
            return <small class="text-info fw-bold me-1">new</small>
        } else if (label === 'hot') {
            return <small class="text-primary fw-bold me-1">hot</small>
        } else if (label === 'promo') {
            return <small class="text-warning fw-bold me-1">promo</small>
        } else if (label === 'maintenance') {
            return <small class="text-danger fw-bold me-1">off</small>
        }
    })
}

export const renderServiceStatus = (status) => {
    if (status === 'active') {
        return (
            <span data-bs-toggle="tooltip" data-bs-placement="top" title="Active">
                <CheckCircle className="text-success" size={18} />
            </span>
        )
    } else if (status === 'maintenance') {
        return (
            <span data-bs-toggle="tooltip" data-bs-placement="top" title="Maintenance">
                <XCircle className="text-danger" size={18} />
            </span>
        )
    } else {
        return (
            <span data-bs-toggle="tooltip" data-bs-placement="top" title="Hidden">
                <MinusCircle className="text-secondary" size={18} />
            </span>
        )
    }
}
