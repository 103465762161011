import React, { createContext, useContext, useRef, useState, useEffect } from 'react'
import Swal from 'sweetalert2'

const historyContext = createContext(null)

export const HistoryProvider = ({ children }) => {
    const initResult = {
        status: '',
        message: '',
    }
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
    })

    const Confirm = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger me-2',
        },
        buttonsStyling: false,
    })

    const imageIDRef = useRef(null)

    const [result, setResult] = useState(initResult)
    const [dataHistory, setDataHistory] = useState(null)
    const [user, setUser] = useState(0)
    const [services, setServices] = useState([])

    const [filter, setFilter] = useState({
        value: 'all',
        label: 'Semua',
        server: '',
    })

    const [isDownload, setIsDownload] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [page, setPage] = useState(1)
    const [totalItem, setTotalItem] = useState(0)

    const handlePageChange = (pages) => {
        getHistory(pages, filter)
        setPage(page)
    }

    const handleRedownload = async (e, image_id, slug, api_provider) => {
        e.preventDefault()
        setIsLoading(true)
        Toast.fire({
            icon: 'info',
            title: 'Sedang mendownload ulang',
            timer: 0,
        })
        const data = {
            url_id: image_id,
            slug: slug,
            api_provider: api_provider,
        }
        try {
            const response = await axios.post(`/api/v2/download/single`, data)
            if (response.status === 200) {
                Toast.fire({
                    icon: response.data.status,
                    title: response.data.message,
                }).then((res) => {
                    if (res.isDismissed) {
                        getHistory()
                        getUser()
                    }
                })
            } else {
                setIsLoading(false)
            }
        } catch (error) {
            setIsLoading(false)
            Toast.fire({
                icon: 'error',
                title: 'Terjadi kesalahan, silahkan coba lagi',
            })
            console.log(error)
        }
    }

    const handleFilter = (filter) => {
        setFilter(filter)
        getHistory(1, filter)
    }

    const handleDownload = async (e, task_id, api_provider) => {
        e.preventDefault()
        setIsDownload(true)

        const payload = {
            task_id: task_id,
            api_provider: api_provider,
        }

        try {
            const response = await axios.post(`/api/v2/download/generate`, payload)
            if (response.data.success && response.status === 200) {
                Toast.close()
                window.location.href = response.data.downloadLink
                setIsDownload(false)
            } else {
                Toast.close()
                setIsDownload(false)
            }
        } catch (error) {
            setIsDownload(false)
            setResult({
                status: 'error',
                message: 'Terjadi kesalahan, silahkan coba lagi',
            })
            console.log(error)
        }
    }

    const handleSearch = async () => {
        setIsLoading(true)

        setPage(1)

        const response = await axios.get(`/api/v2/download/history?search=${imageIDRef.current.value}`)
        if (response.status === 200) {
            setDataHistory(response.data.data.data)
            setTotalItem(response.data.data.total)
            setPage(response.data.data.current_page)
            setIsLoading(false)
        } else {
            setIsLoading(false)
        }
    }

    const getHistory = async (pages, filter) => {
        setIsLoading(true)
        console.log(filter)

        if (filter === undefined || filter === null) {
            filter = ''
        } else {
            filter = `&provider=${filter.value}&server=${filter.server}`
        }

        const response = await axios.get(`/api/v2/download/history?page=${pages}${filter}`)
        if (response.status === 200) {
            setDataHistory(response.data.data.data)
            setTotalItem(response.data.data.total)
            setPage(response.data.data.current_page)
            setIsLoading(false)
        } else {
            setIsLoading(false)
        }
    }

    const getServices = async (pages) => {
        const response = await axios.get(`/api/v2/service`)
        if (response.status === 200) {
            let data = []
            data.push({
                value: 'all',
                label: 'Semua',
                server: '',
            })
            response.data.data.map((item) => {
                data.push({
                    value: item.slug,
                    label: item.name,
                    server: item.server,
                })
            })
            setServices(data)
        }
    }

    const getUser = async () => {
        const response = await axios.get('/api/v2/user/current')
        if (response.status === 200) {
            setUser(response.data.data)
        }
    }

    const renderDownloadButton = (item) => {
        if (item.status === 'success') {
            if (item.server === 'vip') {
                return (
                    <button
                        onClick={(e) => {
                            handleDownload(e, item.url, item.server)
                            Toast.fire({
                                title: 'Sedang mempersiapkan file...',
                                icon: 'info',
                                timer: 0,
                            })
                        }}
                        className="btn btn-xs btn-primary"
                        disabled={isDownload}
                    >
                        Download
                    </button>
                )
            } else {
                return (
                    <a href={`/api/v2/download/generate/${item.id}`} className="btn btn-xs btn-primary">
                        Download
                    </a>
                )
            }
        } else if (item.status === 'expired') {
            if (item.full_url) {
                return (
                    <button
                        disabled={isLoading}
                        className="btn btn-xs btn-outline-danger"
                        onClick={(e) =>
                            Confirm.fire({
                                title: 'Yakin ingin mendownload ulang file ini?',
                                text: 'Anda akan dikenakan biaya sebesar ' + item.services.cost,
                                icon: 'info',
                                showCancelButton: true,
                                confirmButtonText: 'Redownload',
                                cancelButtonText: 'Tidak',
                                reverseButtons: true,
                            }).then((res) => {
                                if (res.value) {
                                    handleRedownload(e, item.full_url, item.services.slug, item.server)
                                }
                            })
                        }
                    >
                        Redownload
                    </button>
                )
            } else {
                return null
            }
        }
    }

    useEffect(() => {
        getHistory(1)
        getUser()
        getServices()
    }, [])

    return (
        <historyContext.Provider
            value={{
                state: {
                    result,
                    isLoading,
                    dataHistory,
                    user,
                    isDownload,
                    page,
                    totalItem,
                    services,
                    filter,
                    imageIDRef,
                },
                action: {
                    handleRedownload,
                    handleDownload,
                    Toast,
                    Confirm,
                    renderDownloadButton,
                    handlePageChange,
                    setFilter,
                    handleFilter,
                    handleSearch,
                },
            }}
        >
            {children}
        </historyContext.Provider>
    )
}

export default function useHistory() {
    return useContext(historyContext)
}
