import React, {
  createContext,
  useContext,
  useRef,
  useState,
  useEffect,
} from "react"
import Swal from "sweetalert2"

const batchContext = createContext(null)

export const BatchProvider = ({ children }) => {
  const initResult = {
    status: "",
    message: "",
  }
  const initBatchResult = {
    total: 0,
    process:0,
    success: 0,
    error: 0,
    messages : [],
  }
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  })

  const batchRef = useRef(null)

  const [server,setServer] = useState(1)
  const [services,setServices] = useState([])
  const [batchResult, setBatchResult] = useState(initBatchResult)
  const [dataHistory, setDataHistory] = useState(null)
  const [user, setUser] = useState(0)

  const [isRequest, setIsRequest] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleChangeServer = (e) => {
    setServer(e)
  }

  const handleRedownload = async (e, image_id) => {
    e.preventDefault()
    setIsLoading(true)

    const data = {
      url_id: batchRef.current.value,
      slug:provider.slug,
      api_provider:provider.api_provider,
    }
    const response = await axios.post(`/api/v2/download/single`, data)

    if (response.status === 200) {
      Toast.fire({
        icon: response.data.status,
        title: response.data.message,
      }).then((res) => {
        if (res.isDismissed) {
          getHistory()
          getCredit()
        }
      })
    } else {
      setIsLoading(false)
    }
  }
  

  const handleBatch = (e) => {
    e.preventDefault()
    setBatchResult(initBatchResult)
    const value = batchRef.current.value.replace(/\r\n/g, "\n").split("\n")
    setBatchResult((prev) => ({
      ...prev,
      total: value.length,
    }))

    if(value.length > 10){
      Toast.fire({
        icon: "error",
        title: "Jumlah maksimal 10 URL/ID",
      })
    }else {
      setIsRequest(true)
      value.forEach((element) => {
        axios.post(`/api/v2/download/batch/server-${server}`, {
          url_id: element,
        }).then((response) => {
          if(response.data.status === "success"){
            setBatchResult((prev) => ({
              ...prev,
              success: prev.success + 1,
              process: prev.process + 1,
              messages : [...prev.messages, response.data]
            }))
            getHistory()
            getUser()
          }else if(response.data.status === "error"){
            setBatchResult((prev) => ({
              ...prev,
              error: prev.error + 1,
              process: prev.process + 1,
              messages : [...prev.messages, response.data]
            }))
          }
        }).catch((error) => {
          setBatchResult((prev) => ({
            ...prev,
            error: prev.error + 1,
            process: prev.process + 1,
            messages : [{status:'error',message:'Terjadi kesalahan, silahkan coba beberapa saat lagi'}]
          }))
        })
      })
      setIsRequest(false)
    }
  }

  const getServices = async () => {
    const response = await axios.get(`/api/v2/service`)
    if (response.status === 200) {
      setServices(response.data.data)
    }
  }

  const getHistory = async () => {
    setIsLoading(true)
    const response = await axios.get(`/api/v2/download/history`)
    if (response.status === 200) {
      setDataHistory(response.data.data)
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  }

  const getUser = async () => {
    const response = await axios.get("/api/v2/user/current")
    if (response.status === 200) {
      setUser(response.data.data)
    }
  }

  useEffect(() => {
    getHistory()
    getUser()
    getServices()
  }, [])

  return (
    <batchContext.Provider
      value={{
        state: {
          isRequest,
          isLoading,
          batchRef,
          dataHistory,
          user,
          batchResult,
          server,
          services
        },
        action: {
          handleRedownload,
          handleBatch,
          handleChangeServer
        },
      }}
    >
      {children}
    </batchContext.Provider>
  )
}

export default function useOrder() {
  return useContext(batchContext)
}
