import React from 'react'
import useService from '../../hooks/useService'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

function Edit(props) {
    const { state, action } = useService()

    return (
        <Modal show={state.showEditModal} onHide={action.closeEditModal}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Layanan</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="modal-body">
                    <div class="mb-3">
                        <input id="id" type="hidden" name="id" />
                        <label htmlFor="nama" class="form-label text-muted">
                            Nama
                        </label>
                        <input
                            name="name"
                            class="form-control"
                            id="nama"
                            value={state.form?.name}
                            onChange={(e) => {
                                action.setForm({
                                    ...state.form,
                                    name: e.target.value,
                                })
                            }}
                        />
                    </div>
                    <div class="mb-3">
                        <label htmlFor="cost" class="form-label text-muted">
                            Harga
                        </label>
                        <input
                            name="cost"
                            class="form-control"
                            id="cost"
                            value={state.form?.cost}
                            onChange={(e) => {
                                action.setForm({
                                    ...state.form,
                                    cost: e.target.value,
                                })
                            }}
                        />
                    </div>
                    <div class="mb-3">
                        <label htmlFor="vip_cost" class="form-label text-muted">
                            Harga VIP
                        </label>
                        <input
                            name="vip_cost"
                            class="form-control"
                            id="vip_cost"
                            value={state.form?.vip_cost}
                            onChange={(e) => {
                                action.setForm({
                                    ...state.form,
                                    vip_cost: e.target.value,
                                })
                            }}
                        />
                    </div>
                    <div class="mb-3">
                        <label htmlFor="bonus_cost" class="form-label text-muted">
                            Harga Bonus
                        </label>
                        <input
                            name="bonus_cost"
                            class="form-control"
                            id="bonus_cost"
                            value={state.form?.bonus_cost}
                            onChange={(e) => {
                                action.setForm({
                                    ...state.form,
                                    bonus_cost: e.target.value,
                                })
                            }}
                        />
                    </div>
                    <div class="mb-3">
                        <label htmlFor="group" class="form-label text-muted">
                            Group
                        </label>
                        <input
                            name="group"
                            class="form-control"
                            id="group"
                            value={state.form?.group}
                            onChange={(e) => {
                                action.setForm({
                                    ...state.form,
                                    group: e.target.value,
                                })
                            }}
                        />
                    </div>
                    <div class="mb-3">
                        <label htmlFor="group" class="form-label text-muted">
                            File Type
                        </label>
                        <input
                            name="file_type"
                            class="form-control"
                            id="file_type"
                            value={state.form?.file_type}
                            onChange={(e) => {
                                action.setForm({
                                    ...state.form,
                                    file_type: e.target.value,
                                })
                            }}
                        />
                    </div>
                    <div class="mb-3">
                        <label htmlFor="group" class="form-label text-muted">
                            Path
                        </label>
                        <input
                            name="path"
                            class="form-control"
                            id="path"
                            value={state.form?.path}
                            onChange={(e) => {
                                action.setForm({
                                    ...state.form,
                                    path: e.target.value,
                                })
                            }}
                        />
                    </div>
                    <div class="mb-3">
                        <label htmlFor="group" class="form-label text-muted">
                            API Provider
                        </label>
                        <input
                            name="path"
                            class="form-control"
                            id="path"
                            value={state.form?.api_provider}
                            onChange={(e) => {
                                action.setForm({
                                    ...state.form,
                                    api_provider: e.target.value,
                                })
                            }}
                        />
                    </div>
                    <div class="mb-3">
                        <label htmlFor="description" class="form-label text-muted">
                            Label
                        </label>
                        <div className="d-flex">
                            <button
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault()
                                    action.handleLabel('new')
                                }}
                                className={`btn btn-xs me-2 ${state.form?.label?.includes('new') ? 'btn-info' : 'btn-outline-info'}`}
                            >
                                new
                            </button>
                            <button
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault()
                                    action.handleLabel('hot')
                                }}
                                className={`btn btn-xs me-2 ${state.form?.label?.includes('hot') ? 'btn-danger' : 'btn-outline-danger'}`}
                            >
                                hot
                            </button>
                            <button
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault()
                                    action.handleLabel('promo')
                                }}
                                className={`btn btn-xs me-2 ${
                                    state.form?.label?.includes('promo') ? 'btn-warning' : 'btn-outline-warning'
                                }`}
                            >
                                promo
                            </button>
                            <button
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault()
                                    action.handleLabel('maintenance')
                                }}
                                className={`btn btn-xs me-2 ${
                                    state.form?.label?.includes('maintenance') ? 'btn-secondary' : 'btn-outline-secondary'
                                }`}
                            >
                                off
                            </button>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label htmlFor="description" class="form-label text-muted">
                            Status
                        </label>
                        <div className="d-flex">
                            <button
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault()
                                    action.handleStatus('active')
                                }}
                                className={`btn btn-xs me-2 ${state.form?.status === 'active' ? 'btn-primary' : 'btn-outline-primary'}`}
                            >
                                active
                            </button>
                            <button
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault()
                                    action.handleStatus('hide')
                                }}
                                className={`btn btn-xs me-2 ${state.form?.status === 'hide' ? 'btn-secondary' : 'btn-outline-secondary'}`}
                            >
                                Hide
                            </button>
                            <button
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault()
                                    action.handleStatus('maintenance')
                                }}
                                className={`btn btn-xs me-2 ${state.form?.status === 'maintenance' ? 'btn-danger' : 'btn-outline-danger'}`}
                            >
                                Maintenance
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={action.closeEditModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={(e) => action.handleUpdate(e)}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default Edit
