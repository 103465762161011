import React from "react";
import useOrder from "../hooks/useOrder";
import {
    CreditCard,
    DownloadCloud,
    AlertTriangle,
    CheckCircle,
} from "react-feather";

function SingleForm() {
    const { state, action } = useOrder();

    return (
        <form className="forms-sample" onSubmit={(e) => state.provider.status === "active" ? action.handleOrder(e) : null}>
            <div className="mb-3">
                <label className="form-label">{state.provider.slug == 'istockphoto_video_hdweb_essentials' ? "ID Video" : "URL Gambar"}</label>
                <input
                    disabled={state.provider.status !== "active"}
                    ref={state.urlRef}
                    className="form-control"
                    autoComplete="off"
                />
            </div>
            <div className="d-flex align-items-center justify-content-between mb-3">
                {state.provider.status === "active" ? (
                    <button
                        type="submit"
                        className="btn btn-primary btn-icon-text"
                        disabled={state.isRequest}
                    >
                        <DownloadCloud className="btn-icon-prepend" />
                        <span className="px-1">Download</span>
                    </button>
                ) : null}
                <div>
                    <a
                        href="/topup"
                        className="btn btn-sm btn-outline-primary btn-icon-text"
                    >
                        <CreditCard className="btn-icon-prepend" />
                        Topup Credit
                    </a>
                </div>
            </div>
            {state.isRequest || state.result.status ? (
                <div>
                    <div
                        class={`alert ${
                            state.result.status === "error"
                                ? "alert-danger"
                                : state.result.status === "success"
                                ? "alert-success"
                                : "alert-primary"
                        }`}
                        role="alert"
                    >
                        {state.result.status === "error" ? (
                            <AlertTriangle />
                        ) : state.result.status === "success" ? (
                            <CheckCircle />
                        ) : (
                            <i className="mdi mdi-loading mdi-spin"></i>
                        )}
                        {state.result.message
                            ? state.result.message
                            : "Sedang mendownload..."}
                    </div>
                </div>
            ) : null}
        </form>
    );
}

export default SingleForm;
