import React from "react";
import ReactDOM from "react-dom";
import Chart from "react-apexcharts";
import useChart, { ChartProvider } from "../../hooks/useChart";

function DownloadChart(props) {
    const { state, action } = useChart();
    const colors = {
        primary: "#6571ff",
        secondary: "#7987a1",
        success: "#05a34a",
        info: "#66d1d1",
        warning: "#fbbc06",
        danger: "#ff3366",
        light: "#e9ecef",
        dark: "#060c17",
        muted: "#7987a1",
        gridBorder: "rgba(77, 138, 240, .15)",
        bodyColor: "#000",
        cardBg: "#fff",
    };
    const series = [
        {
            name: "Download",
            data: state.download,
        },
    ];
    const options = {
        chart: {
            type: "area",
            parentHeightOffset: 0,
            foreColor: colors.bodyColor,
            background: colors.cardBg,
            toolbar: {
                show: false,
            },
        },
        theme: {
            mode: "light",
        },
        colors: ["#BF89F5", colors.danger, colors.warning],
        dataLabels: {
            enabled: false,
        },
        grid: {
            padding: {
                bottom: -4,
            },
            borderColor: colors.gridBorder,
            xaxis: {
                lines: {
                    show: true,
                },
            },
        },
        stroke: {
            width: 2,
            curve: "smooth",
        },
        labels: state.downloadLabel,
        xaxis: {
            type: "text",
        },
        yaxis: {
            opposite: true,
        },
        legend: {
            horizontalAlign: "left",
        },
    };

    return (
        <div class="card-body">
            <div class="d-flex justify-content-between align-items-baseline mb-2">
                <h6 class="card-title mb-0">Total Download</h6>
            </div>
            <div class="row align-items-start mb-2">
                <div class="col-md-6">
                    <p class="text-muted tx-13 mb-3 mb-md-0">Total download</p>
                </div>
                <div class="col-md-6 d-flex justify-content-md-end">
                    <div
                        class="btn-group mb-3 mb-md-0"
                        role="group"
                        aria-label="Basic example"
                    >
                        <button
                            class={`btn btn-xs ${
                                state.downloadState === "monthly"
                                    ? "btn-primary"
                                    : "btn-outline-primary"
                            }`}
                            onClick={() => {
                                action.getChart("download", "monthly");
                                action.setDownloadState("monthly");
                            }}
                        >
                            Bulan ini
                        </button>
                        <button
                            class={`btn btn-xs ${
                                state.downloadState === "yearly"
                                    ? "btn-primary"
                                    : "btn-outline-primary"
                            }`}
                            onClick={() => {
                                action.getChart("download", "yearly");
                                action.setDownloadState("yearly");
                            }}
                        >
                            Tahun ini
                        </button>
                    </div>
                </div>
            </div>
            {state.isDownloadLoading ? (
                <div className="d-flex justify-content-center align-items-center h-100">
                    <i className="mdi mdi-loading mdi-spin me-2 mdi-24px"></i>
                    <h4>Loading</h4>
                </div>
            ) : (
                <Chart
                    options={options}
                    series={series}
                    type="area"
                    height={275}
                />
            )}
        </div>
    );
}

if (document.getElementById("download-chart")) {
    ReactDOM.render(
        <ChartProvider>
            <DownloadChart />
        </ChartProvider>,
        document.getElementById("download-chart")
    );
}
