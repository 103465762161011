/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

require('./bootstrap');

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

require('./components/Example');
require('./components/Batch/BatchDownload');
require('./components/Order/SingleForm');
require('./components/Order/HistoryDownload');
require('./components/Order/Order');
require('./components/History/History');
require('./components/Admin/Dashboard/DownloadChart');
require('./components/Admin/Dashboard/PaymentChart');
require('./components/Admin/Dashboard/ServicesChart');
require('./components/Admin/Services/Services');

