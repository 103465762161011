import React from 'react'
import ReactDOM from 'react-dom'
import useHistory, { HistoryProvider } from '../hooks/useHistory'
import { formatTime, renderIcon, renderStatus } from '../hooks/useHelper'
import Pagination from 'react-js-pagination'
import Select from 'react-select'

function History() {
    const { state, action } = useHistory()

    return (
        <div className="card">
            <div className="card-body">
                <div className="m-1 mb-4">
                    <h4 className="noble-ui-logo d-block">History Download</h4>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-7 col-md-8 mb-3">
                        <div className="d-flex">
                            <div className="d-flex align-items-end justify-content-end w-100">
                                <div className="me-2 w-100">
                                    <label className="text-muted">Cari</label>
                                    <input className="form-control" ref={state.imageIDRef} />
                                </div>
                                <button className="btn btn-primary w-25" onClick={() => action.handleSearch()}>
                                    Cari
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5 col-md-4 d-flex justify-content-end mb-3">
                        <div className="d-flex align-items-end justify-content-end w-100">
                            <div className="me-2 w-100">
                                <label className="text-muted">Provider</label>
                                <Select value={state.filter} onChange={(e) => action.handleFilter(e)} options={state.services} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-3 table-responsive">
                    <table className="table table-hover mb-0">
                        <thead>
                            <tr>
                                <th className="pt-0 text-center">thumbnail</th>
                                <th className="pt-0">Provider</th>
                                <th className="pt-0">ID</th>
                                <th className="pt-0">Tanggal</th>
                                <th className="pt-0">Status</th>
                                <th className="pt-0 text-end">Harga</th>
                                <th className="pt-0 text-center">URL</th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.isLoading ? (
                                <tr>
                                    <td className="text-center py-5" colSpan={7}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <i className="mdi mdi-loading mdi-spin me-2 mdi-24px"></i>
                                            <h4>Loading</h4>
                                        </div>
                                    </td>
                                </tr>
                            ) : (
                                state.dataHistory?.map((item, index) => (
                                    <tr key={index}>
                                        <td className="align-middle text-center">
                                            <img src={item.thumbnail} className="thumbnail" alt="thumbnail" />
                                        </td>
                                        <td className="align-middle">{item.service_name}</td>
                                        <td className="align-middle">{item.image_id}</td>
                                        <td className="align-middle">{formatTime(item.created_at)}</td>
                                        <td className="align-middle">{renderStatus(item.status)}</td>
                                        <td className="align-middle text-end">
                                            {renderIcon(item.credit_from === item.credit_to ? 'bonus' : 'credit', 'sm', item.cost)}
                                        </td>
                                        <td className="align-middle text-center">{action.renderDownloadButton(item)}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>

                    <div className="mt-3">
                        <Pagination
                            activePage={state.page}
                            itemsCountPerPage={10}
                            totalItemsCount={state.totalItem}
                            pageRangeDisplayed={5}
                            onChange={action.handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

function DashboardHistory() {
    const { state, action } = useHistory()

    return (
        <div className="card-body">
            <div className="d-flex justify-content-between align-items-baseline mb-4">
                <h6 className="card-title mb-0">Unduhan Terakhir</h6>

                <a className="btn btn-xs btn-outline-primary btn-icon-text" href="/history/download">
                    <i className="btn-icon-prepend" data-feather="file-text"></i>
                    History
                </a>
            </div>
            <div className="mb-3 table-responsive">
                <table className="table table-hover mb-0">
                    <thead>
                        <tr>
                            <th className="pt-0 text-center">thumbnail</th>
                            <th className="pt-0">Provider</th>
                            <th className="pt-0">ID</th>
                            <th className="pt-0">Tanggal</th>
                            <th className="pt-0">Status</th>
                            <th className="pt-0 text-end">Harga</th>
                            <th className="pt-0 text-center">URL</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.isLoading ? (
                            <tr>
                                <td className="text-center py-5" colSpan={7}>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <i className="mdi mdi-loading mdi-spin me-2 mdi-24px"></i>
                                        <h4>Loading</h4>
                                    </div>
                                </td>
                            </tr>
                        ) : (
                            state.dataHistory?.slice(0, 5).map((item, index) => (
                                <tr key={index}>
                                    <td className="align-middle text-center">
                                        <img src={item.thumbnail} className="thumbnail" alt="thumbnail" />
                                    </td>
                                    <td className="align-middle">{item.service_name}</td>
                                    <td className="align-middle">{item.image_id}</td>
                                    <td className="align-middle">{formatTime(item.created_at)}</td>
                                    <td className="align-middle">{renderStatus(item.status)}</td>
                                    <td className="align-middle text-end">
                                        {renderIcon(item.credit_from === item.credit_to ? 'bonus' : 'credit', 'sm', item.cost)}
                                    </td>
                                    <td className="align-middle text-center">{action.renderDownloadButton(item)}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

if (document.getElementById('history-page')) {
    ReactDOM.render(
        <HistoryProvider>
            <History />
        </HistoryProvider>,
        document.getElementById('history-page'),
    )
}

if (document.getElementById('dashboard-history-page')) {
    ReactDOM.render(
        <HistoryProvider>
            <DashboardHistory />
        </HistoryProvider>,
        document.getElementById('dashboard-history-page'),
    )
}
