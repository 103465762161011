import React from "react";
import ReactDOM from "react-dom";
import Chart from "react-apexcharts";
import useChart, { ChartProvider } from "../../hooks/useChart";

function ServicesChart(props) {
    const { state, action } = useChart();
    const colors = {
        primary: "#6571ff",
        secondary: "#7987a1",
        success: "#05a34a",
        info: "#66d1d1",
        warning: "#fbbc06",
        danger: "#ff3366",
        light: "#e9ecef",
        dark: "#060c17",
        muted: "#7987a1",
        gridBorder: "rgba(77, 138, 240, .15)",
        bodyColor: "#000",
        cardBg: "#fff",
    };

    let series = [
        {
            name: "Download",
            data: state.provider,
        },
    ];

    let options = {
        chart: {
            type: "bar",
            height: "100%",
        },
        plotOptions: {
            bar: {
                borderRadius: 0,
                position: "top",
                dataLabels: {
                    orientation: "vertical",
                }
            },
        },
        dataLabels: {
            enabled: true,
            textAnchor: "start",
            style: {
                fontSize: "9px",
            },
            formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
            },
            offsetY: 0,
            dropShadow: {
                enabled: true,
            },
        },
        xaxis: {
            categories: state.providerLabel,
            labels: {
                show: false
            },

        },
    };

    return (
        <div class="card-body p-0">
            <Chart options={options} series={series} type="bar" height="100%" />
        </div>
    );
}
if (document.getElementById("services-chart")) {
    console.lo;
    ReactDOM.render(
        <ChartProvider>
            <ServicesChart />
        </ChartProvider>,
        document.getElementById("services-chart")
    );
}
